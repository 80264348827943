define("discourse/plugins/discourse-push-notifications/discourse/components/push-notification-config", ["exports", "discourse-common/utils/decorators", "discourse/lib/push-notifications"], function (_exports, _decorators, _pushNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_obj = {
    showSetting: function showSetting() {
      return this.siteSettings.desktop_push_notifications_enabled;
    },
    actions: {
      change: function change(enable) {
        if (enable) {
          this.currentUser.set("custom_fields.discourse_push_notifications_prefer_push", true);

          _pushNotifications.keyValueStore.setItem("prefer_push", "true");
        } else {
          this.currentUser.set("custom_fields.discourse_push_notifications_prefer_push", false);

          _pushNotifications.keyValueStore.setItem("prefer_push", "");
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showSetting", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showSetting"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-push-notifications/discourse/initializers/setup-push-notifications", ["exports", "discourse/lib/plugin-api", "discourse/lib/push-notifications"], function (_exports, _pluginApi, _pushNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "setup-push-notifications",
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        var siteSettings = container.lookup("site-settings:main");

        if (!Ember.testing && api.getCurrentUser()) {
          if (siteSettings.desktop_push_notifications_enabled) {
            //open class up, add property for saving on notifications
            api.modifyClass("controller:preferences/notifications", {
              pluginId: "discourse-push-notifications",
              saveAttrNames: ["muted_usernames", "new_topic_duration_minutes", "auto_track_topics_after_msecs", "notification_level_when_replying", "like_notification_frequency", "allow_private_messages", "custom_fields"]
            });
            api.modifyClass("component:desktop-notification-config", {
              pluginId: "discourse-push-notifications",
              isPushNotificationsPreferred: function isPushNotificationsPreferred() {
                if (!this.site.mobileView && !_pushNotifications.keyValueStore.getItem("prefer_push")) {
                  return false;
                }

                return (0, _pushNotifications.isPushNotificationsSupported)(this.site.mobileView);
              }
            }); // add key, prefer push

            if (api.getCurrentUser().custom_fields["discourse_push_notifications_prefer_push"]) {
              _pushNotifications.keyValueStore.setItem("prefer_push", "true");
            } else {
              _pushNotifications.keyValueStore.setItem("prefer_push", "");
            }
          } else {
            _pushNotifications.keyValueStore.setItem("prefer_push", "");
          }
        }
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/push-notification-config"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSetting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[10,\"class\",\"checkbox-label\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"checked\",[24,[\"currentUser\",\"custom_fields\",\"discourse_push_notifications_prefer_push\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"change\"],[[\"value\"],[\"target.checked\"]]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n    \"],[1,[28,\"i18n\",[\"discourse_push_notifications.note\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/push-notification-config"}});
Ember.TEMPLATES["javascripts/connectors/user-preferences-desktop-notifications/user-push-notification-input"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[22,\"push-notification-config\"],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/user-preferences-desktop-notifications/user-push-notification-input"}});

